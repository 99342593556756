import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import AccountDelete from "../../../components/account-delete"

const ToolboxDeleteAccountPage = () => {
  return (
    <Layout>
      <SEO title="Account Deletion" />
      <AccountDelete app="Toolbox for Magnus"/>
    </Layout>
  )
}

export default ToolboxDeleteAccountPage
